<template>
  <div class="auth-wrapper auth-v2" :style="backgroundStyle">
    <b-row class="auth-inner m-0" align-v="center">
      <!-- Login-->
      <b-col
        cols="12"
        md="6"
        offset-md="3"
        class="d-flex align-items-center p-0 my-0 col"
      >
        <b-card class="px-2 mx-auto my-3 auth-bg w-100">
          <safe-img :src="communityLogo" class="forgot-logo mb-3 mt-2 round" />
          <h1 class="font-weight-bold w-100 text-center mb-2" title-tag="h1">
            {{ translate(community.name) }}
          </h1>

          <div v-if="success === false">
            <b-card-text class="text-center h3">
              {{ $t('forgot.subtitle') }}
            </b-card-text>
            <b-card-text class="text-center h4">
              {{ $t('forgot.subtitle2') }}
            </b-card-text>

            <!-- form -->
            <validation-observer ref="forgotForm" #default="{invalid}">
              <b-form class="auth-forgot-form mt-4" @submit.prevent="forgot">
                <!-- email -->
                <b-form-group label="" label-for="forgot-email">
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('forgot.form.email.label')"
                    vid="email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="forgot-email"
                      v-model="userEmail"
                      :state="errors.length > 0 ? false : null"
                      name="forgot-email"
                      :placeholder="$t('forgot.form.email.placeholder')"
                      :aria-label="$t('forgot.form.email.label')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- submit buttons -->
                <b-button
                  class="mt-2 boton"
                  type="submit"
                  squared
                  variant="primary"
                  block
                  :disabled="invalid"
                >
                  {{ $t('forgot.form.action.reset') }}
                </b-button>
              </b-form>
            </validation-observer>
          </div>
          <div v-else>
            <b-alert variant="success" show>
              {{ $t('forgot.form.action.success')
              }}<b-link href="mailto:forgot.form.action.email">
                {{ $t('forgot.form.action.email') }}
              </b-link>
            </b-alert>
          </div>

          <b-card-text class="text-center mt-2 small mb-0">
            <b-link @click="$router.push({ path: `/${communitySlug}/login` })">
              <span>&nbsp;{{ $t('forgot.return') }}</span>
            </b-link>
          </b-card-text>

          <b-card-text class="text-center  mt-3">
            <b-link :href="landingURL" target="_blank">
              <b-img :src="brand" alt="Powered by Nectios" class="brand" />
            </b-link>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, email } from '@validations';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import { getImageResource } from '@/@core/utils/image-utils';
import SafeImg from '@core/components/safe-img/SafeImg.vue';
import Service from '@/config/service-identifiers';
import BrandLogo from '@/assets/images/brand/powerby-nectios.svg';
import { getNewAuthRoute } from '@/auth/utils';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';

export default {
  components: {

    SafeImg,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility, ToastNotificationsMixin],
  data() {
    return {
      success: false,
      userEmail: '',

      // validation rules
      required,
      email,
      community: null,

      isLoading: true,

      from: null,
      path: null,
    };
  },
  computed: {
    backgroundStyle() {
      const backgrounds = this.community?.customization?.theme?.login?.backgrounds || [];

      if (backgrounds.length) {
        return {
          backgroundImage: `url(${getImageResource(backgrounds[0])}`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat !important',
        };
      }
      return null;
    },
    communitySlug() {
      return this.$route.params.communityId;
    },
    user() {
      return this.$store.getters.loggedUser;
    },
    brand() {
      return BrandLogo;
    },
    landingURL() {
      return process.env.VUE_APP_LANDING_URL_COMMUNITY;
    },
    communityLogo() {
      return getImageResource(
        this.community?.customization?.theme?.login?.logo
          || this.community?.header?.logo_url
          || this.community?.logoURL,
      );
    },
  },
  watch: {
    setLocale(newLocale) {
      this.$store.dispatch('setLocale', newLocale);
    },
  },
  created() {
    this.updateCommunity();
    if (process.env.VUE_APP_ENV !== 'development') {
      window.location.href = getNewAuthRoute({ ...this.community, slug: this.communitySlug }, '/forgot');
    }
  },
  methods: {
    translate(field) {
      return translateTranslationTable(this.$store.state.locale.currentLocale, field);
    },
    async updateCommunity() {
      this.isLoading = true;

      const response = await this.$store.$service[Service.BackendClient].get('login', {
        params: { communitySlug: this.communitySlug },
      });
      this.community = response.data.data;
      /* Community colors */
      if (this.community.community_customizations.theme) {
        const customization = this.community.community_customizations.theme;
        if (customization.primaryColor) {
          document.documentElement.style.setProperty(
            '--primary-color',
            customization.primaryColor,
          );
          let hsl = customization.primaryColor;
          hsl = hsl.substring(4, hsl.length - 1);
          hsl = hsl.split(',');
          document.documentElement.style.setProperty(
            '--primary-color-h',
            hsl[0],
          );
          document.documentElement.style.setProperty(
            '--primary-color-s',
            hsl[1],
          );
          document.documentElement.style.setProperty(
            '--primary-color-l',
            hsl[2],
          );
        }
        if (customization.secondaryColor) {
          document.documentElement.style.setProperty(
            '--secondary-color',
            customization.secondaryColor,
          );
          let hslSecondary = customization.secondaryColor;
          hslSecondary = hslSecondary.substring(4, hslSecondary.length - 1);
          hslSecondary = hslSecondary.split(',');
          document.documentElement.style.setProperty(
            '--secondary-color-h',
            hslSecondary[0],
          );
          document.documentElement.style.setProperty(
            '--secondary-color-s',
            hslSecondary[1],
          );
          document.documentElement.style.setProperty(
            '--secondary-color-l',
            hslSecondary[2],
          );
        }
      }
      /* Finish colors */
      // if (this.community.showChatbot === 1) {
      //   const chatBot = document.createElement('script');
      //   chatBot.innerText = 'window.$crisp=[];window.CRISP_WEBSITE_ID="a1aa36a2-c0e5-44a4-bc75-c5f95f1af801";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();';
      //   document.body.appendChild(chatBot);
      // }
      if (this.community.showChatbot === 2) {
        const chatBot = document.createElement('div');
        chatBot.innerHTML = this.$store.getters.currentCollective?.customChatbot;
        document.body.appendChild(chatBot);
      }

      if (this.community.mainCommunitySlug && this.community.mainCommunitySlug !== this.communitySlug && !this.community.bypass) {
        this.from = this.communitySlug;
        this.path = this.$route.params.path;
        await this.$router.replace({
          name: 'auth-login',
          params: { communityId: this.community.mainCommunitySlug },
        });

        return;
      }

      this.isLoading = false;
      this.setLocale();
    },
    async forgot() {
      // const success = await this.$refs.loginForm.validate();
      // if (success) {
      try {
        const response = await this.$store.dispatch('password', {
          email: this.userEmail,
          communitySlug: this.communitySlug,
        });
        this.comprobacion(response);

        // if (this.community.mainCommunitySlug && this.community.mainCommunitySlug !== this.communitySlug) {
        //   return this.$router.replace({ name: this.path, params: { communityId: this.from } });
        // }
        // return this.$router.replace({ name: 'Resumen', params: { communityId: this.$route.params.communityId } });
      } catch (error) {
        this.notifyError(this.$t('login.forgot-password.error'));
      }
      // }
      return '';
    },

    comprobacion(response) {
      if (response.data.ok != null) {
        this.success = true;
      }
    },
    setLocale() {
      this.$store.dispatch('setLocale', this.community?.language);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-auth.scss';
.forgot-logo {
  width: 120px;
  margin: auto;
  display: block;
}
.brand {
  height: 32px;
}
.community-text {
  padding-left: 6px;
  margin-top: 4px;
  vertical-align: bottom;
  color: black;
}
.boton {
  font-size: 1.3em;
}
.col {
  height: 100vh;
}
</style>
